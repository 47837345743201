import { useTheme } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { tokens } from '../../../theme'
import axios from 'axios'
import { GLOBAL_API } from '../../../data/Links';
import { getAuthentication } from '../../../auth';
import { Box, CircularProgress, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Header from '../../../components/Header';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewAccount from './ViewAccount';

const API_URL = `${GLOBAL_API}/dashapi`;

const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`

const AppUserGQlSchema = `
id
MainInfo{
  FirstName
  LastName
  PhoneNumber{
    CountryCode
    Number
  }
  Country{
    ${CountryGQLSchema}
  }
  BirthDate
}
Credentials{
  Email
}
SocialMedia{
  isSet
  Links{
    Facebook
    Twitter
    Instagram
  }
}
Infos{
  CreatedAt
}
`
const AccountsPage = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [isLoaderVisible, setIsLoaderVisible] = useState('flex')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [modalOpen,setModalOpen] = useState(false)
  const [modalStatus,setModalStatus] = useState('VIEW')

  const [accounts, setAccounts] = useState([])
  const [accountsCount, setAccountsCount] = useState(0)

  const [selectedAccount, setSelectedAccount] = useState(null)



  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0)
  }
  const handleCloseModal = () =>{
    setModalOpen(false)
}
  

  const ModalComponents = {
    "VIEW":<ViewAccount account={selectedAccount} closingModal={handleCloseModal} />
  }


  const handleViewAccount = (account) => {
    setModalOpen(true)
    setModalStatus('VIEW')
    setSelectedAccount(account)
}

  const fetchDataCount = async() =>{
    const res = await axios.post(API_URL,{
        query:`
        query{
            GetStaticNumberByKey(key:"AccountsCount"){
                Key
                Value
            }
        }
        `
    }, {
        headers: {
          token: `Bearer ${getAuthentication()}`
        }
    })

    if(res.data.data.GetStaticNumberByKey){
        setAccountsCount(res.data.data.GetStaticNumberByKey.Value)
    }
    else{
        setAccountsCount(0)
    }

}

  const fetchData = async () => {
    setIsLoaderVisible('flex')
    fetchDataCount()
    const res = await axios.post(API_URL,{
      query: `query{
        GetAListOfAccounts(offset:${page},limit:${rowsPerPage}){
          ${AppUserGQlSchema}
        }
      }`
    },{
      headers: {
        token: `Bearer ${getAuthentication()}`
      }
    })
    if(res.data.data.GetAListOfAccounts){
      setAccounts(res.data.data.GetAListOfAccounts)
    }
    setIsLoaderVisible('none')
  }

  useEffect(()=>{
    fetchData()
  },[])

  useEffect(()=>{
    fetchData()
  },[page,rowsPerPage])

  return (
    <Box m='20px'>
        <Header title={`Accounts`} subtitle={`Manage Accounts`} />
        <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            {ModalComponents[modalStatus]}
        </Modal>
        <Box sx={{mt:2,display:'flex',justifyContent:'space-between'}}>
          <Box   sx={{display:isLoaderVisible === 'flex' ? 'flex' : 'none',justifyContent:"center",alignContent:"center",alignItems:"center",}}>
             <CircularProgress color="secondary"  sx={{mx:2}}/>Fetching Data ... Please Wait
          </Box>
        </Box>
        <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                            Full Name
                        </TableCell>
                        <TableCell>
                          Country
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                          accounts.map((account)=>(
                            <TableRow>
                              <TableCell>
                                {account.MainInfo.FirstName} {account.MainInfo.LastName}
                              </TableCell>
                              <TableCell>
                                {account.MainInfo.Country.Name.En} - {account.MainInfo.Country.Name.Ar}
                              </TableCell>
                              <TableCell>
                                  <IconButton color='info' onClick={()=>handleViewAccount(account)} >
                                      <RemoveRedEyeIcon />

                                  </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={accountsCount}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>  
              
          </Box>
    </Box>
  )
}

export default AccountsPage