import { Box, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import axios from 'axios';
import { GLOBAL_API } from "../../../data/Links";
import { getAuthentication } from '../../../auth';
import PlayedGame from './PlayedGame';
const API_URL = `${GLOBAL_API}/dashapi`;
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const SportEventsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Venue
StartTime
isLive
Ended
Score{
    Home
    Away
}
`

const SoccerScorePredictionGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        En
        Ar
    }
    Slug
    StartDate
   
}
MainImage{
    FilePath
}
SportEvents{
   ${SportEventsGQLSchema}
}
Participants{
    id
}
Awards{
    Index
    isWin
    Award
}
Infos{
    SportEventsReady
    ParticipantsCount
    Published
    Locked
    isJudge
    CreatedAt
}
`

const PlayedGameGQLSchema = `
id

GameType
Game{
    ${SoccerScorePredictionGQLSchema}
}
Status
Predictions
JoinDate
Result
Award
`
const ViewAccount = ({account,closingModal}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [playedGames,setPlayedGames] = useState([])


    const fetchPlayedGames = async () => {
        const res = await axios.post(API_URL,{
            query:`query{
                GetPlayedGameOfAccount(id:"${account.id}"){
                    ${PlayedGameGQLSchema}
                }
            }`
        },{
            headers: {
                token: `Bearer ${getAuthentication()}`
              }
        })
        if(res.data.data.GetPlayedGameOfAccount){   
            setPlayedGames(res.data.data.GetPlayedGameOfAccount)
        }
    }
  
    useEffect(()=>{
        if(account){
            fetchPlayedGames()
        }
    },[account])
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "80%",height:"80%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
        <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
        <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Account</Typography>
        <Typography sx={{textAlign:"center"}} variant="h6">{account.MainInfo.FirstName} {account.MainInfo.LastName}</Typography>
        <br/>
    <Divider /> 
    <Box>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Birthdate</TableCell>
                    <TableCell>Joined Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{account.MainInfo.FirstName} {account.MainInfo.LastName}</TableCell>
                    <TableCell>{account.MainInfo.Country.Name.En} - {account.MainInfo.Country.Name.Ar}</TableCell>
                    <TableCell><a href={`tel:${account.MainInfo.PhoneNumber.CountryCode}${account.MainInfo.PhoneNumber.Number}`}>{account.MainInfo.PhoneNumber.CountryCode} {account.MainInfo.PhoneNumber.Number}</a></TableCell>
                    <TableCell><a href={`mailto:${account.Credentials.Email}`}>{account.Credentials.Email}</a></TableCell>
                    <TableCell>{new Date(account.MainInfo.BirthDate).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(account.Infos.CreatedAt).toLocaleDateString()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </Box>
    <br />
    <Divider />
    <br />
    <Typography variant="h4" color="secondary" >Played Games</Typography>
    <br />
    <Box>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Game Type</TableCell>
                    <TableCell>Game Name</TableCell>
                    <TableCell>Join Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Award</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    playedGames.map((game)=>(
                        <PlayedGame key={game.id} game={game} />
                    ))
                }
            </TableBody>
        </Table>
    </Box>

    </Box>
  )
}

export default ViewAccount