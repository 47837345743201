import { Box, Button, CircularProgress, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import ImageIcon from '@mui/icons-material/Image';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { getAuthentication } from '../../../auth';
import { GLOBAL_API } from '../../../data/Links';
import AddNewTopCompetition from './AddNewTopCompetition';
import EditTopCompetition from './EditTopCompetition';
import DeleteIcon from '@mui/icons-material/Delete';

const API_URL = `${GLOBAL_API}/dashapi`;

const TopCompetitionsGqlSchema = `
id
Competition{
    id
    Sport{
        Name{
            En
            Ar
        }
    }
    MainInfo{
        Name{
            En
            Ar
        }
    }
}
Count
`
const TopCompetitions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const [modalStatus,setModalStatus] = useState('ADD')
    const [toAddNew,setToAddNew] = useState(true)
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')
    const [selectedTopCompetition,setSelectedTopCompetition] = useState(null)
    
    const [topCompetitions,setTopCompetitions] = useState([])

    const addNewSportHandler = (competition,count) =>{
        addNewTopCompetitionFunc(competition,count)
    }

    const editSportHandler = (count) =>{
        editTopCompeititionCount(count)
    }
    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    const ModalComponents = {
        "ADD":<AddNewTopCompetition closingModal={handleCloseModal} handleSubmit={addNewSportHandler} />,
        "EDIT":<EditTopCompetition topcompetition={selectedTopCompetition} handleSubmit={editSportHandler} closingModal={handleCloseModal} />,
    }

    

    const handleNewBtnClick = () =>{
        setToAddNew(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }

    const handleShowImageEditor = (sprt) =>{
        setModalOpen(true)
        setSelectedTopCompetition(sprt)
        setModalStatus('IMAGE')
    }

    const handleEditSportEditor = (sprt) => {
        setModalOpen(true)
        setSelectedTopCompetition(sprt)
        setModalStatus('EDIT')

    }

    const addNewTopCompetitionFunc = async(competition,count) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    AddNewTopCompetition(comp:"${competition}",count:${count}){
                        ${TopCompetitionsGqlSchema}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.AddNewTopCompetition.id){
            fetchData()
        }
    }

    const DeleteTopCompetition = async(comp) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    DeleteTopCompetition(id:"${comp.id}")
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })

        if(res.status === 200){
            fetchData()
        }
    }

    const editTopCompeititionCount = async(count) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditTopCompetitionCount(id:"${selectedTopCompetition.id}",count:${count}){
                        ${TopCompetitionsGqlSchema}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.EditTopCompetitionCount.id){
            fetchData()
        }
    }

 

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }

    const fetchData = async() =>{
        setLoaderVisibility('visible')
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetTopCompetitions{
                        ${TopCompetitionsGqlSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })

        if(res.status === 200){
            setTopCompetitions(res.data.data.GetTopCompetitions)
            setLoaderVisibility('hidden')

        }
    }

    useEffect(()=>{
        fetchData()
    },[])

  return (
    <Box m={`20px`}>
        <Header title={`Top Competitions`} subtitle={`Add,Edit Top Competitions`} />
        <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            {ModalComponents[modalStatus]}
        </Modal>
       <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"}}>
                    <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Top Competiton</Button>

                    <Box   sx={{display:"flex",visibility:isLoaderVisible,justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>

        </Box>

        <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {border: "none",},
                "& .MuiTable-root":{borderRadius:"15px"},
                "& .MuiDataGrid-cell": {borderBottom: "none",},
                "& .name-column--cell": {color: colors.greenAccent[300],},
                "& .MuiTableHead-root": {backgroundColor: colors.blueAccent[700],borderBottom: "none",},
                "& .MuiTableBody-root": {backgroundColor: colors.primary[400],},
                "& .MuiDataGrid-footerContainer": {borderTop: "none",backgroundColor: colors.blueAccent[700],},
                "& .MuiCheckbox-root": {color: `${colors.greenAccent[200]} !important`,},
                "& .MuiTableCell-root":{borderBottom:'none',textAlign:"center"},
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{color:`${colors.grey[100]} !important`}}}>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Competition</TableCell>
                                <TableCell>Sport</TableCell>
                                <TableCell>Count</TableCell>
                                <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    topCompetitions.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map((sC)=>(
                                        <TableRow key={sC.id}>
                                            <TableCell>{sC.Competition.MainInfo.Name.En} - {sC.Competition.MainInfo.Name.Ar}</TableCell>
                                            <TableCell>{sC.Competition.Sport.Name.En} - {sC.Competition.Sport.Name.Ar}</TableCell>
                                            <TableCell>{sC.Count}</TableCell>
                                            <TableCell color='secondary'>
                                                <IconButton onClick={()=>handleEditSportEditor(sC)} >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color='error' onClick={()=>DeleteTopCompetition(sC)}>    
                                                    <DeleteIcon />
                                                </IconButton>   
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={topCompetitions.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>  
            </Box>
    </Box>
  )
}

export default TopCompetitions