import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close';

import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/dashapi`

const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`

const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const CompetitorsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`
const CompetitionsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
}
`
const SportEventsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Compitiors{
    Home{
        ${CompetitorsGQLSchema}
    }
    Away{
        ${CompetitorsGQLSchema}
    }
}
Year{
    ${SportSeasonGQLSchema}
}
Competition{
    ${CompetitionsGQLSchema}
}
Venue
StartTime
isLive
Ended
Score{
    Home
    Away
}
`
const SoccerScorePredictionGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name
    Slug
    StartDate
    Composition{
        ${CompetitionsGQLSchema}
    }
}
MainImage{
    FilePath
}
SportEvents{
   ${SportEventsGQLSchema}
}
Participants{
    id
}
Awards{
    Index
    isWin
    Award
}
Infos{
    SportEventsReady
    ParticipantsCount
    Published
    Locked
    CreatedAt
}
`
const AddNewSoccerScorePrediction = ({handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [competitions,setCompetitions] = useState([])
  const [sports,setSports] = useState([])
  const [seasons,setSeasons] = useState([])

  const [selectedCompetition,setSelectedCompetition] = useState(null)
  const [selectedSport,setSelectedSport] = useState(null)
  const [selectedSeason,setSelectedSeason] = useState(null)

  const [en,setEn] = useState('')
  const [ar,setAr] = useState('')

  const [showSpinner,setShowSpinner] = useState(true)


  const HandleSubmitBtn = () =>{
    handleSubmit(selectedSport,selectedCompetition,selectedSeason,en,ar)
    closingModal()
  }


  const fetchDataSports = async() =>{
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetSports{
                    ${SportGQLSCHEMA}
                }
            }
        `
    },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.status === 200){
        setSports(res.data.data.GetSports)

    }
}
  const fetchCompetitions = async() =>{
      const res = await axios.post(API_URL,{
        query:`
            query{
                GetAllCompetitions(sport:"${selectedSport}"){
                    ${CompetitionsGQLSchema}
                }
            }
        `
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
     })
     if(res.data.data.GetAllCompetitions){
        setCompetitions(res.data.data.GetAllCompetitions)

     }
  }

  const fetchSeasons = async() =>{
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetSportSeason(id:"${selectedCompetition}"){
                    ${SportSeasonGQLSchema}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetSportSeason){
        setSeasons(res.data.data.GetSportSeason)
    }
  }


  useEffect(()=>{
    setSeasons([])
    if(selectedCompetition !== null){
      fetchSeasons()
    }
  },[selectedCompetition])

  useEffect(()=>{
    setCompetitions([])
    setSeasons([])
    if(selectedSport !== null){
      fetchCompetitions()
    }
  },[selectedSport])

  useEffect(()=>{
    fetchDataSports()
  },[])
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "50%",height:"80%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Add New Soccer Score Prediction Game</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">Select a competition from the list below to populate the fields with the necessary data.</Typography>
    <br/>
    <Divider />
    <br />
    <FormControl sx={{mt:3}} fullWidth>
      <InputLabel id="demo-simple-select-label" color="secondary">Select A Sport</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSport}
        onChange={(e)=>setSelectedSport(e.target.value)}
        label="Competitions"
        color="secondary"
      >
        {
          sports.map((lC)=>(
            <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
    {
      competitions.length > 0 && <FormControl sx={{mt:3}} fullWidth>
      <InputLabel id="demo-simple-select-label" color="secondary">Select A Competition</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCompetition}
        onChange={(e)=>setSelectedCompetition(e.target.value)}
        label="Competitions"
        color="secondary"
      >
        {
          competitions.map((lC)=>(
            <MenuItem value={lC.id}>{lC.MainInfo.Name.En} - {lC.MainInfo.Name.Ar}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
    }
    {
      seasons.length > 0 && <FormControl sx={{mt:3}} fullWidth>
      <InputLabel id="demo-simple-select-label" color="secondary">Select A Season</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSeason}
        onChange={(e)=>setSelectedSeason(e.target.value)}
        label="Competitions"
        color="secondary"
      >
        {
          seasons.map((lC)=>(
            <MenuItem value={lC.id}>{lC.Year}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
    }
    <br />
    <Box sx={{display:"flex",justifyContent:'space-around'}}>
          <TextField 
                  type="text"
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  value={en}
                  onChange={(e)=>setEn(e.target.value)}
                  label="English Name ..."
                  color="secondary"/>
          <TextField 
                  type="text"
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  value={ar}
                  onChange={(e)=>setAr(e.target.value)}
                  label="Arabic Name ..."
                  color="secondary"/>
    </Box>
       
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Game</Button>  
  </Box>
  )
}

export default AddNewSoccerScorePrediction