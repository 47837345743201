import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from '@mui/icons-material/Close';
import Tags from "@yaireo/tagify/dist/react.tagify" 
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/dashapi`

const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`

const CompetitionGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        En
        Ar
    }
}
`
const AddNewTopCompetition = ({handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const tagifyRef = useRef()

  const [sports,setSports] = useState([])
  const [competitions,setCompetitions] = useState([])

  const [selectedSport,setSelectedSport] = useState(null)
  const [selectedCompetition,setSelectedCompetition] = useState(null)
  const [count,setCount] = useState(1)

  const HandleSubmitBtn = () =>{
    handleSubmit(selectedCompetition,count)
    closingModal()
  }


 
  const fetchSports = async() =>{
    const res = await axios.post(API_URL,{
      query:`
        query{
          GetSports{
              ${SportGQLSCHEMA}
          }
        }
      `
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.data.data.GetSports){
      setSports(res.data.data.GetSports)
    }
  }

  const fetchCompetition = async () =>{
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetAllCompetitions(sport:"${selectedSport}"){
                    ${CompetitionGQLSchema}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })

    if(res.data.data.GetAllCompetitions){
        setCompetitions(res.data.data.GetAllCompetitions)
    }
  }

  useEffect(()=>{
    if(selectedSport){
        fetchCompetition()
    }
  },[selectedSport])

  useEffect(()=>{
    fetchSports()
  },[])
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "50%",height:"60%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Add New Top Competition</Typography>
    <br/>
    <Divider />
    <br />
  
    <FormControl sx={{mt:3}} fullWidth>
      <InputLabel id="demo-simple-select-label" color="secondary">Select A Sport</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSport}
        label="Competitions"
        onChange={(e)=>setSelectedSport(e.target.value)}
        color="secondary"
      >
        {
          sports.map((lC)=>(
            <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
          ))
        }
      </Select>
    </FormControl>   

    {
        competitions.length > 0 && <FormControl sx={{mt:3}} fullWidth>
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Competition</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCompetition}
          label="Competitions"
          onChange={(e)=>setSelectedCompetition(e.target.value)}
          color="secondary"
        >
          {
            competitions.map((lC)=>(
              <MenuItem value={lC.id}>{lC.MainInfo.Name.En} - {lC.MainInfo.Name.Ar}</MenuItem>
            ))
          }
        </Select>
      </FormControl>   
    }
 
    <Box>
            <TextField
                fullWidth
                id="filled-multiline-static"
                label="Count ..."
                sx={{ gridColumn: "span 2",mt:3 }}
                onChange={(e)=>setCount(e.target.value)}
                defaultValue={count}
                variant="filled"
                color="secondary"
                />

            </Box>
       
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Competition</Button>  
  </Box>
  )
}

export default AddNewTopCompetition