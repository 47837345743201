import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close';

import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const DASH_API = `${GLOBAL_API}/dashapi`
const EXTERNAL_URL = `${GLOBAL_API}/SportRadar/Soccer`

const CompetitionGQLSCHEMA = `
id
CompetitionId
MainInfo{
    Name{
        En
        Ar
    }
}
`
const EditSoccerWinPrediction = ({competition,handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [competitions,setCompetitions] = useState([])
  const [ar,setAr] = useState(competition.MainInfo.Name.Ar)
  const [en,setEn] = useState(competition.MainInfo.Name.En)
 




  const HandleSubmitBtn = () =>{
    handleSubmit(en,ar)
    closingModal()
  }


 
  
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "50%",height:"50%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Edit Soccer Win Prediction Game Data</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">{competition.MainInfo.Name.En} - {competition.MainInfo.Name.Ar}</Typography>
    <br/>
    <Divider />
    <br />
    
    <Box sx={{display:"flex",justifyContent:'space-between'}}>
          <TextField 
                  type="text"
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  value={en}
                  onChange={(e)=>setEn(e.target.value)}
                  label="English Name ..."
                  color="secondary"/>
           <TextField 
                  type="text"
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  value={ar}
                  onChange={(e)=>setAr(e.target.value)}
                  label="Arabic Name ..."
                  color="secondary"/>
    </Box>
   
    
       
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Edit Game</Button>  
  </Box>
  )
}

export default EditSoccerWinPrediction