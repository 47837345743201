import { TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'

const PlayedGame = ({game}) => {
  return (
    <TableRow>
        <TableCell>{game.GameType === 'win' ? 'Win Prediction Game' : 'Score Prediction Game'}</TableCell>
        <TableCell>{game.Game.MainInfo.Name.En} - {game.Game.MainInfo.Name.Ar}</TableCell>
        <TableCell>{game.JoinDate && new Date(game.JoinDate).toLocaleDateString()}</TableCell>
        <TableCell>
            {game.Status === 'Pending' && <Typography>Pending</Typography>}
            {game.Status === 'Win' && <Typography color='secondary'>Win</Typography>}
            {game.Status === 'Lose' && <Typography color='error'>Lose</Typography>}
        </TableCell>
        <TableCell>{game.Result} Games</TableCell>
        <TableCell>{game.Game.Award}</TableCell>
    </TableRow>
  )
}

export default PlayedGame