import { useTheme } from '@emotion/react'
import React, { useRef, useState } from 'react'
import { tokens } from '../../../theme'
import { Box, Button, Divider, IconButton, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Tags from "@yaireo/tagify/dist/react.tagify" 

const AddNewSport = ({closingModal, handleSubmit}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const tagifyRef = useRef()

    const [en,setEn] = useState('')
    const [ar,setAr] = useState('')
    const [keywords,setKeywords] = useState([])
    const [description,setDescription] = useState('')

    const HandleSubmitBtn = () =>{
        handleSubmit(en,ar,keywords,description)
        closingModal()
    }


    return (
         <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "60%",height:"60%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
            <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
                <Typography variant="h3" color="secondary" sx={{
                textAlign:"center"
                }}>Add New Sport</Typography>
                <Typography sx={{textAlign:"center"}} variant="h6">Sport Can be not deleted ... only edited</Typography>
            <br/>
            <Divider />
            <br />
            <Box sx={{
                display:"flex",
                flexDirection:"row",
                justifyContent:"space-between"
            }}>
                 <TextField 
                        sx={{
                        mt:3,
                        width:"49%"
                        }}
                        variant="filled"
                        type="text"
                        value={en}
                        onChange={(e)=>setEn(e.target.value)}
                        label="Sport Name English ..."
                        color="secondary"
                    />
                     <TextField 
                        sx={{
                        mt:3,
                        width:"49%"
                        }}
                        variant="filled"
                        type="text"
                        value={ar}
                        onChange={(e)=>setAr(e.target.value)}
                        label="Sport Name Arabic ..."
                        color="secondary"
                    />
            </Box>
            <Box>
            <Tags  defaultValue={keywords} tagifyRef={tagifyRef} color="secondary" settings={{
                placeholder:"Seo Keywords ..."
            }} onChange={(e) => setKeywords(e.detail.tagify.value.map((tag) => tag.value))} />
            </Box>
            <Box>
            <TextField
                fullWidth
                id="filled-multiline-static"
                label="Seo Description ..."
                multiline
                rows={4}
                sx={{ gridColumn: "span 2",mt:3 }}
                onChange={(e)=>setDescription(e.target.value)}
                defaultValue={description}
                variant="filled"
                color="secondary"
                />

            </Box>
            <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Sport</Button>

        </Box>
    
    )
}

export default AddNewSport