import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, Switch, Autocomplete } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/dashapi`
const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`
const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`

const CompetitionsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
}
`
const CompetitorsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`

const AddNewSportEvent = ({handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const tagifyRef = useRef()

  const [sports,setSports] = useState([])
  const [compettitons,setCompetitions] = useState([])
  const [seasons,setSeasons] = useState([])  
  const [countries,setCountries] = useState([])
  const [isTeam,setIsTeam] = useState(true)

  const [home,setHome] = useState(null)
  const [away,setAway] = useState(null)
  const [homeCompotitors,setHomeCompotitors] = useState([])
  const [awayCompotitors,setAwayCompotitors] = useState([])
  const [selectedSport,setSelectedSport] = useState(null)
  const [selectedCompetition,setSelectedCompetition] = useState(null)
  const [selectedSeason,setSelectedSeason] = useState(null)
  const [selectedHomeCountry,setSelectedHomeCountry] = useState(null)
  const [selectedAwayCountry,setSelectedAwayCountry] = useState(null)

  const [venue,setVenue] = useState('')
  const [year,setYear] = useState('')
  const [month,setMonth] = useState('')
  const [day,setDay] = useState('')
  const [hour,setHour] = useState('')
  const [minute,setMinute] = useState('')
  const [startTime,setStartTime] = useState('')
  const [tempTime,setTempTime] = useState('')
  

  

  const HandleSubmitBtn = () =>{
    handleSubmit(selectedSport,home,away,selectedSeason,selectedCompetition,venue,startTime)
    alert('Sport Event Added Successfully')
  }

  const fetchCountries = async() => {
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetCountryList{
                    ${CountryGQLSchema}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })

    if(res.data.data.GetCountryList){
        setCountries(res.data.data.GetCountryList)
    }
  }
  const fetchSeasons = async() =>{
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetSportSeason(id:"${selectedCompetition}"){
                    ${SportSeasonGQLSchema}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetSportSeason){
        setSeasons(res.data.data.GetSportSeason)
    }
  }

  const fetchCompetitions = async() =>{
   
    const res = await axios.post(API_URL,{
        query:`
        query{
            GetAllCompetitions(sport:"${selectedSport}"){
                ${CompetitionsGQLSchema}
            }
        }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetAllCompetitions){
        setCompetitions(res.data.data.GetAllCompetitions)
    }
  }
 
  const fetchHomeCompotitors = async() => {
    let state = 'ALL'
    if(selectedHomeCountry === 'ALL'){
        state = "ALL"
    }
    else{
        state = 'COUNTRY'
    }
    if(selectedHomeCountry){
      const res = await axios.post(API_URL,{
          query:`
              query{
                  GetAllCompotitors(sport:"${selectedSport}",country:"${selectedHomeCountry!== 'ALL' ? selectedHomeCountry.value : selectedHomeCountry}",searchby:"${state}",isteam:${isTeam}){
                      ${CompetitorsGQLSchema}
                  }
              }
              
          `
      },{
          headers:{
              token:`Bearer ${getAuthentication()}`
          }
      })

      if(res.data.data.GetAllCompotitors){
          setHome(null)
          setAway(null)
          setHomeCompotitors(res.data.data.GetAllCompotitors)
          setAwayCompotitors(res.data.data.GetAllCompotitors)
          setSelectedAwayCountry(selectedHomeCountry)
      }
    }
    else{
      setHomeCompotitors([])
      setAwayCompotitors([])
    }
  }

  const fetchAwayCompotitors = async() => {
    let state = 'ALL'
    if(selectedAwayCountry === 'ALL'){
        state = "ALL"
    }
    else{
        state = 'COUNTRY'
    }
    if(selectedAwayCountry){
      const res = await axios.post(API_URL,{
          query:`
              query{
                  GetAllCompotitors(sport:"${selectedSport}",country:"${selectedAwayCountry!== 'ALL' ? selectedAwayCountry.value : selectedAwayCountry}",searchby:"${state}",isteam:${isTeam}){
                      ${CompetitorsGQLSchema}
                  }
              }
              
          `
      },{
          headers:{
              token:`Bearer ${getAuthentication()}`
          }
      })

      if(res.data.data.GetAllCompotitors){
          setAway(null)
          setAwayCompotitors(res.data.data.GetAllCompotitors)
      }
    }
    else{
      setAwayCompotitors([])
    }
  }

  const fetchSports = async() =>{
    const res = await axios.post(API_URL,{
      query:`
        query{
          GetSports{
              ${SportGQLSCHEMA}
          }
        }
      `
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.data.data.GetSports){
      setSports(res.data.data.GetSports)
    }
  }

 
  const getDateComponents = (datetime) => {
    const date = new Date(datetime);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    
    setYear(year);
    setMonth(month);
    setDay(day);
    setHour(hour);
    setMinute(minute);
  }
  
  useEffect(() => {
    setStartTime(`${year}-${month}-${day}T${hour}:${minute}:00.000Z`);
  }, [year, month, day, hour, minute]);
  


  useEffect(()=>{
    if(selectedHomeCountry !== null && selectedSport !== null){
          fetchHomeCompotitors()
    }
  },[selectedHomeCountry,selectedSport,isTeam])

  useEffect(()=>{
    if(selectedAwayCountry !== null && selectedSport !== null){
          fetchAwayCompotitors()
    }
  },[selectedAwayCountry,selectedSport,isTeam])

  useEffect(()=>{
    if(selectedCompetition !== null){
        fetchSeasons()
        setSelectedSeason(null)
    }
  },[selectedCompetition])

  useEffect(()=>{
    if(selectedSport !== null){
      fetchHomeCompotitors()
    }
  },[isTeam])

  useEffect(()=>{
    if(selectedSport !== null){
        fetchCompetitions()
        setSelectedCompetition(null)
    }
  },[selectedSport])

  useEffect(()=>{
    if(tempTime !== ''){
      getDateComponents(tempTime)
    }
  },[tempTime])

  useEffect(()=>{
    fetchCountries()
    fetchSports()
  },[])

  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "80%",height:"80%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Add New Sport Event</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">You Can't Delete Sport Event ... You Can Just Edit The Score</Typography>
    <br/>
    <Divider />
    <br />

    <Box sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between"
    }}>
    <FormControl sx={{mt:3,width:"49%"}}>
    <InputLabel id="demo-simple-select-label" color="secondary">Select A Sport</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSport}
        label="Competitions"
        onChange={(e)=>setSelectedSport(e.target.value)}
        color="secondary"
      >
        {
          sports.map((lC)=>(
            <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
        <Box sx={{mt:3,width:'49%'}}>
        <Switch checked={isTeam} onChange={()=>{
              if(isTeam){
                setSelectedHomeCountry('ALL')
                setSelectedAwayCountry('ALL')
              }
              else{
                setSelectedHomeCountry(null)
                setSelectedAwayCountry(null)
              }
              setIsTeam(!isTeam)

              }} color="secondary" />
            <span>Check it For Team / Uncheck it for national Team</span>
        </Box>
   

    </Box>
  
  

    {
        compettitons.length > 0 &&   <FormControl sx={{mt:3}} fullWidth>
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Competiton</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCompetition}
          label="Competitions"
          onChange={(e)=>setSelectedCompetition(e.target.value)}
          color="secondary"
        >
          {
            compettitons.map((lC)=>(
              <MenuItem value={lC.id}>{lC.MainInfo.Name.En} - {lC.MainInfo.Name.Ar}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    }
   {
        seasons.length > 0 &&   <FormControl sx={{mt:3}} fullWidth>
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Season</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedSeason}
          label="Competitions"
          onChange={(e)=>setSelectedSeason(e.target.value)}
          color="secondary"
        >
          {
            seasons.map((lC)=>(
              <MenuItem value={lC.id}>{lC.Year}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    }

        <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        <Autocomplete
      disablePortal
      id="combo-box-demo"
      fullWidth
      disabled={!isTeam}
      onChange={(event, newValue) => {
        setSelectedHomeCountry(newValue);
      }}
      sx={{width:'49%'}}
      value={selectedHomeCountry}
      options={countries.map((cnt)=>{
        return {
          label:cnt.Name.En+ ' - '+cnt.Name.Ar,
          value:cnt.id
        }
      })}
      renderInput={(params) => <TextField  fullWidth   sx={{mt:3,width:'100%'}}
      {...params} label="Select A Country" color="secondary"/>} />
       <Autocomplete
      disablePortal
      id="combo-box-demo"
      fullWidth
      disabled={!isTeam}
      onChange={(event, newValue) => {
        setSelectedAwayCountry(newValue);
      }}
      sx={{width:'49%'}}
      value={selectedAwayCountry}
      options={countries.map((cnt)=>{
        return {
          label:cnt.Name.En+ ' - '+cnt.Name.Ar,
          value:cnt.id
        }
      })}
      renderInput={(params) => <TextField  fullWidth   sx={{mt:3,width:'100%'}}
      {...params} label="Select A Country" color="secondary"/>} />

        </Box>

    {
        (homeCompotitors.length > 0 && awayCompotitors.length > 0) ? <Box sx={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between"
        }}>
    
    <FormControl sx={{mt:3,width:"49%"}}>
        <InputLabel id="demo-simple-select-label" color="secondary">Select The Home Team</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={home}
            label="Competitions"
            onChange={(e)=>setHome(e.target.value)}
            color="secondary"
          >
            {
              homeCompotitors.map((lC)=>(
                <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
    
        <FormControl sx={{mt:3,width:"49%"}}>
          <InputLabel id="demo-simple-select-label" color="secondary">Select The Away</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={away}
            label="Competitions"
            onChange={(e)=>setAway(e.target.value)}
            color="secondary"
          >
              {
              awayCompotitors.map((lC)=>(
                <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
            
        </Box> : selectedSport ? <Typography>There are no compotitors according to above filters</Typography> : <Typography>Please select the sport</Typography>
    }
    
   
    
    <Box sx={{display:"flex",justifyContent:'space-around'}}>
          <TextField 
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  type="text"
                  value={venue}
                  onChange={(e)=>setVenue(e.target.value)}
                  label="Venue ..."
                  color="secondary"
                 
                />
                <Box sx={{width:"49%"}}>
                    <TextField type="datetime-local" sx={{mt:3,width:'100%'}}  color="secondary" value={tempTime} onChange={(e)=>setTempTime(e.target.value)} />
                    {
                        startTime !== '' && <Typography>Date in UTC:  {startTime}</Typography>
                    }
                </Box>
          
    </Box>
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Sport Event</Button>  
  </Box>
  )
}

export default AddNewSportEvent