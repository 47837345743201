import { Autocomplete, Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { tokens } from '../../../theme';
import axios from "axios";
import Header from "../../../components/Header"
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { getAuthentication } from '../../../auth';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImageIcon from '@mui/icons-material/Image';
import PreviewIcon from '@mui/icons-material/Preview';
import { GLOBAL_API } from '../../../data/Links';
import AddNewCompotitor from './AddNewCompotitor';
import EditImage from './EditImage';
import EditCompotitor from './EditCompotitor';
const API_URL = `${GLOBAL_API}/dashapi`;

const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`

const CompetitorsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`

const SoccerCompetitors = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [compotitors,setCompetitors] = useState([])
    const [selectedCompotitor,setselectedCompotitor] = useState()

    const [toAddNew,setToAddNew] = useState(true)
    const [page,setPage] = useState(0)
    const [rowsPerPage,setRowsPerPage] = useState(5)
    const [modalOpen,setModalOpen] = useState(false)
    const [isLoaderVisible,setLoaderVisibility] = useState('visible')
    const [modalStatus,setModalStatus] = useState('ADD')
    const [selectedSport,setSelctedSport] = useState('')
    const [dataCount,setDataCount] = useState()
    const [sports,setSports] = useState([])
    const [search,setSearch] = useState('')
    const [selectedCountry,setSelectedCountry] = useState('')
    const [countries,setCountries] = useState([])

    const handleCloseModal = () =>{
        setModalOpen(false)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }
    const addNewCompotitorHandler = (selectedSport,en,ar,isTeam,selectedCountry) =>{
        addNewCompotitorFunc(selectedSport,en,ar,isTeam,selectedCountry)
    }
    const editIamgeHandler = (filepath) => {
        editIamgeFunc(filepath)
    }
    const editCompetitionHandler = (en,ar,isTeam,selectedCountry) =>{
        editCompotitorFunc(en,ar,isTeam,selectedCountry)
    }
    const ModalComponents = {
        "ADD":<AddNewCompotitor closingModal={handleCloseModal} handleSubmit={addNewCompotitorHandler} />,
        "IMAGE":<EditImage  compotitor={selectedCompotitor} handleSubmit={editIamgeHandler} closingModal={handleCloseModal}/>,
        "EDIT":<EditCompotitor compotitor={selectedCompotitor} handleSubmit={editCompetitionHandler} closingModal={handleCloseModal}/>
    }
    const handleNewBtnClick = () =>{
        setToAddNew(true)
        setModalOpen(true)
        setModalStatus('ADD')
    }
    const handleShowSeason = (comp) =>{
        setModalOpen(true)
        setselectedCompotitor(comp)
        setModalStatus('SEASON')
    }
    const handleShowImageEditor = (comp) =>{
        setModalOpen(true)
        setselectedCompotitor(comp)
        setModalStatus('IMAGE')
    } 
    const handleEditCompEditor = (comp) => {
        setModalOpen(true)
        setselectedCompotitor(comp)
        setModalStatus('EDIT')
    }
    const handlePreview = (comp) =>{
       
    }
    const addNewCompotitorFunc = async(selectedSport,en,ar,isTeam,selectedCountry) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    AddNewCompotitor(sport:"${selectedSport}",en:"${en}",ar:"${ar}",isteam:${isTeam},country:"${selectedCountry}"){
                        ${CompetitorsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            if(selectedCountry !== ''){
                fetchDataByCountry()
            }
            else{
                fetchData()
            }
        }
    }
    const editIamgeFunc = async(filepath) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditCompotitorImage(id:"${selectedCompotitor.id}",filepath:"${filepath}"){
                        ${CompetitorsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
        
    }
    const editCompotitorFunc = async(en,ar,isTeam,selectedCountry) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    EditCompotitor(id:"${selectedCompotitor.id}",en:"${en}",ar:"${ar}",isteam:${isTeam},country:"${selectedCountry}"){
                        ${CompetitorsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            fetchData()
        }
    }
    const fetchDataCount = async() =>{
        const res = await axios.post(API_URL,{
            query:`
            query{
                GetStaticNumberByKey(key:"Compotitor"){
                    Key
                    Value
                }
            }
            `
        }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })

        if(res.data.data.GetStaticNumberByKey){
            setDataCount(res.data.data.GetStaticNumberByKey.Value)
        }
        else{
            setDataCount(0)
        }

    }
    const fetchDataSports = async() =>{
        setLoaderVisibility('visible')
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetSports{
                        ${SportGQLSCHEMA}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            setSports(res.data.data.GetSports)
            setLoaderVisibility('hidden')

        }
    }
    const fetchData = async() =>{
        setLoaderVisibility('visible')
        fetchDataCount()
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetCompotitors(offset:${page},limit:${rowsPerPage},sport:"${selectedSport}"){
                        ${CompetitorsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            setCompetitors(res.data.data.GetCompotitors)
            setLoaderVisibility('hidden')
        }
    }

    const fetchDataCountries = async() =>{
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetCountryList{
                        ${CountryGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            setCountries(res.data.data.GetCountryList)
        }
    }

    const fetchDataByCountry = async() =>{
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetCompotitorsByCountry(country:"${selectedCountry.value}",offset:${page},limit:${rowsPerPage}){
                        ${CompetitorsGQLSchema}
                    }
                }
            `
        },{
            headers:{
              token:`Bearer ${getAuthentication()}`
            }
        })
        if(res.status === 200){
            setCompetitors(res.data.data.GetCompotitorsByCountry)
        }
    }
    const fetchDataBySearch = async() =>{
        const res = await axios.post(API_URL,{
            query:`
                query{
                    SearchCompotitors(search:"${search}",offset:${page},limit:${rowsPerPage}){
                        ${CompetitorsGQLSchema}
                    }
                }
            `
        },{
            headers:{
                token:`Bearer ${getAuthentication()}`
            }
        })

        if(res.data.data.SearchCompotitors){
            const res_data = res.data.data.SearchCompotitors
            setCompetitors(res_data)
        }
    }

    useEffect(()=>{
        if(search !== ''){
            fetchDataBySearch()
        }
        else if(selectedCountry !== ''){
            fetchDataByCountry()
        }
        else if(selectedCountry !== ''){
            fetchData()
        }
        else{
            fetchData()
        }
    },[search,selectedCountry])
    useEffect(()=>{
        fetchData()
    },[selectedSport])
    useEffect(()=>{
        fetchDataSports()
        fetchDataCountries()
    },[])
    useEffect(()=>{
        if(search !== ''){
            fetchDataBySearch()
        }
        else if(selectedCountry !== ''){
            fetchDataByCountry()
        }
        else if(selectedCountry !== ''){
            fetchData()
        }
        else{
            fetchData()
        }
    },[page,rowsPerPage])
  return (
    <Box m={`20px`}>
        <Header title={`Competitors`} subtitle={`Add,Edit Competitors`} />
        <Modal open={modalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            {ModalComponents[modalStatus]}
        </Modal>
        <Box sx={{
                mb:2,
                width:"100%",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"space-between"}}>
            <Box>
                <Button variant="contained" onClick={handleNewBtnClick} color="secondary">Add New Compotitor</Button>
            </Box>
            <Box   sx={{display:"flex",visibility:isLoaderVisible,justifyContent:"center",alignContent:"center",alignItems:"center",}}>
                <CircularProgress color="secondary"  sx={{mx:2}}/>
                Fetching Data ... Please Wait
            </Box>
            <Box sx={{width:"60%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
            <TextField sx={{width:'33%'}} variant="filled" type="text" value={search} onChange={(e)=>setSearch(e.target.value)} label="Search ..." color="secondary" />
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                fullWidth
                onChange={(event, newValue) => {
                    setSelectedCountry(newValue);
                }}
                sx={{width:'33%'}}
                value={selectedCountry}
                options={countries.map((cnt)=>{
                    return {
                    label:cnt.Name.En+ ' - '+cnt.Name.Ar,
                    value:cnt.id
                    }
                })}
                renderInput={(params) => <TextField  fullWidth   sx={{width:'100%'}}
                {...params} label="Select A Country" color="secondary"/>} />
              
            <FormControl sx={{width:'33%'}}>
                <InputLabel id="demo-simple-select-label" color="secondary">Select A Sport</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedSport}
                    label="Sports"
                    onChange={(e)=>setSelctedSport(e.target.value)}
                    color="secondary">
                        <MenuItem value="">All</MenuItem>
                        {
                            sports.map((lC)=>(
                                <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
        </Box>
       
        <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {border: "none",},
                "& .MuiTable-root":{borderRadius:"15px"},
                "& .MuiDataGrid-cell": {borderBottom: "none",},
                "& .name-column--cell": {color: colors.greenAccent[300],},
                "& .MuiTableHead-root": {backgroundColor: colors.blueAccent[700],borderBottom: "none",},
                "& .MuiTableBody-root": {backgroundColor: colors.primary[400],},
                "& .MuiDataGrid-footerContainer": {borderTop: "none",backgroundColor: colors.blueAccent[700],},
                "& .MuiCheckbox-root": {color: `${colors.greenAccent[200]} !important`,},
                "& .MuiTableCell-root":{borderBottom:'none',textAlign:"center"},
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{color:`${colors.grey[100]} !important`}}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Competitor Name</TableCell>
                                <TableCell>Sport</TableCell>
                                <TableCell>Competitor Type</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>ِActions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    compotitors.map((sC)=>(
                                        <TableRow key={sC.id}>
                                            <TableCell sx={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:1}}>
                                                {
                                                    sC.MainImage?.FilePath && (
                                                        <img width={30} height={30} style={{borderRadius:"50%"}} src={`https://cloud.time4.games/`+sC.MainImage?.FilePath} alt="compotitor" />
                                                    )
                                                }
                                                <Typography variant='h6'>{sC.Name.En} - {sC.Name.Ar}</Typography>
                                            </TableCell>
                                            <TableCell color='secondary'>{sC.Sport.Name.En} - {sC.Sport.Name.Ar}</TableCell>
                                            <TableCell>{sC.isTeam ? "Team" : "National Team"}</TableCell>
                                            <TableCell color='secondary'>{sC.Country?.Name?.En} - {sC.Country?.Name?.Ar}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={()=>handlePreview(sC)}>
                                                    <PreviewIcon />
                                                </IconButton>
                                                <IconButton onClick={()=>handleShowImageEditor(sC)} color='secondary'>
                                                    <ImageIcon />
                                                </IconButton>
                                                <IconButton onClick={()=>handleEditCompEditor(sC)} >
                                                    <EditIcon />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={dataCount}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>  
            </Box>
    </Box>
  )
}

export default SoccerCompetitors