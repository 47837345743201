import React, { useState, useEffect } from "react";

import { Routes, Route } from "react-router-dom";
import axios from "axios";
import TopBar from "./../../global/Topbar";
import SideBar from "./../../global/SideBar";
import Dashboard from "./../../dashboard";
import Team from "./../../team";
import Invoices from "./../../invoices";
import Contacts from "./../../contacts";
import Bar from "./../../bar";
import Form from "./../../form";
import Line from "./../../line";
import Pie from "./../../pie";
import FAQ from "./../../faq";
import Editor from "./../../editor";
import { getAuthentication } from "../../../auth";

import Categories from "../../Sections/Categories";
import Article from "../../Sections/Articles";
import Portfolio from "../../Sections/Portfolio";
import Game from "../../Sections/Game";
import Poll from "../../Sections/Poll";
import Keywords from "../../Sections/Keywords";
import AccountsPage from "../../Sections/Accounts";
import BannerAds from "../../Sections/BannerAds";
import Countries from "../../Sections/Countries";
import SixBetGame from "../../Sections/SoccerScorePrediction";
import SoccerCompetitions from "../../Sections/SoccerCompitition";
import SoccerCompetitors from "../../Sections/SoccerCompetitors";
import SoccerSportEvents from "../../Sections/SoccerSportEvents";
import Sports from "../../Sections/Sports";
import SoccerWinPrediction from "../../Sections/SoccerWinPrediction";
import TopCompetitions from "../../Sections/TopCompetitions";

const Main = ({ signOutHandler }) => {
  const [isUser, setIsUser] = useState(false);
  const [token, setToken] = useState("");
  

 

  return (
    <>
      <SideBar  />
      <main className="content">
        <TopBar signOutHandler={signOutHandler} />
        
        <Routes>
          
        <Route path="/Accounts" element={<AccountsPage />} />
        <Route path="/BannerAds" element={<BannerAds />} />
        <Route path="/Countries" element={<Countries />} />
        <Route path="/Sports" element={<Sports />} />
        <Route path="/Competitions" element={<SoccerCompetitions />} />
        <Route path="/Competitors" element={<SoccerCompetitors />} />
        <Route path="/SportEvents" element={<SoccerSportEvents />} /> 
        <Route path="/ScorePrediction" element={<SixBetGame />} />
        <Route path="/WinPrediction" element={<SoccerWinPrediction />} />
        <Route path="/TopCompetitions" element={<TopCompetitions />} />
        
  
        
        
        </Routes>
      </main>
    </>
  );
};

export default Main;
