import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from '@mui/icons-material/Close';
import Tags from "@yaireo/tagify/dist/react.tagify" 
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/dashapi`

const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const AddNewSoccerCompetition = ({handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const tagifyRef = useRef()

  const [sports,setSports] = useState([])

  const [selectedSport,setSelectedSport] = useState(null)
  const [en, seten] = useState("")
  const [ar, setar] = useState("")
  const [gender, setgender] = useState("")
  const [keywords,setKeywords] = useState([])
  const [description,setDescription] = useState('')


  const HandleSubmitBtn = () =>{
    handleSubmit(selectedSport,en,ar,gender,keywords,description)
    closingModal()
  }


 
  const fetchSports = async() =>{
    const res = await axios.post(API_URL,{
      query:`
        query{
          GetSports{
              ${SportGQLSCHEMA}
          }
        }
      `
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.data.data.GetSports){
      setSports(res.data.data.GetSports)
    }
  }

  useEffect(()=>{
    fetchSports()
  },[])
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "80%",height:"80%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Add New Compition</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">You Can't Delete Competition ... Just Edit It</Typography>
    <br/>
    <Divider />
    <br />
  
    <FormControl sx={{mt:3}} fullWidth>
      <InputLabel id="demo-simple-select-label" color="secondary">Select A Sport</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSport}
        label="Competitions"
        onChange={(e)=>setSelectedSport(e.target.value)}
        color="secondary"
      >
        {
          sports.map((lC)=>(
            <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
   
    
    <Box sx={{display:"flex",justifyContent:'space-around'}}>
          <TextField 
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  type="text"
                  value={en}
                  onChange={(e)=>seten(e.target.value)}
                  label="Competition Name English ..."
                  color="secondary"
                 
                />
          <TextField 
            sx={{
              mt:3,
              width:"49%"
            }}
            variant="filled"
            type="text"
            value={ar}
            onChange={(e)=>setar(e.target.value)}
            label="Competition Name Arabic ..."
            color="secondary"
          />
    </Box>
   
    <Box>
    <FormControl sx={{mt:3}} fullWidth>
      <InputLabel id="demo-simple-select-label" color="secondary">Select Gender</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={gender}
        label="Competitions"
        onChange={(e)=>setgender(e.target.value)}
        color="secondary"
      >
                  <MenuItem value='men'>Male</MenuItem>
                  <MenuItem value='women'>Female</MenuItem>

      </Select>
    </FormControl>
    </Box>

    <Box>
    <Tags  defaultValue={keywords} tagifyRef={tagifyRef} color="secondary" settings={{
                placeholder:"Seo Keywords ..."
            }} onChange={(e) => setKeywords(e.detail.tagify.value.map((tag) => tag.value))} />
    </Box>
    <Box>
            <TextField
                fullWidth
                id="filled-multiline-static"
                label="Seo Description ..."
                multiline
                rows={4}
                sx={{ gridColumn: "span 2",mt:3 }}
                onChange={(e)=>setDescription(e.target.value)}
                defaultValue={description}
                variant="filled"
                color="secondary"
                />

            </Box>
       
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Competition</Button>  
  </Box>
  )
}

export default AddNewSoccerCompetition