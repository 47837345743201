import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/SportRadar/Soccer`
const View = ({competition,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)


 



  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "80%",height:"80%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Competition</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">{competition.MainInfo.Name.En} - {competition.MainInfo.Name.Ar}</Typography>
    <br/>
    <Divider />
   
    <Box sx={{display:"flex",justifyContent:"space-around",mt:2}}>
        <Box sx={{width:"49%"}}>
            <img style={{width:"100%"}} src={GLOBAL_API+'/Storage/'+competition.MainImage.FilePath} />
        </Box>
        <Box sx={{width:"49%"}}>
             <ul>
                <li style={{display:"flex",flexDirection:"row",gap:4,alignItems:"center"}}>
                    <Typography variant="h4">Competition Name English:</Typography>
                    <Typography variant="h5" color={`secondary`}>{competition.MainInfo.Name.En}</Typography>
                </li>
                <li style={{display:"flex",flexDirection:"row",gap:4,alignItems:"center"}}>
                    <Typography variant="h4">Competition Name Arabic:</Typography>
                    <Typography variant="h5" color={`secondary`}>{competition.MainInfo.Name.Ar}</Typography>
                </li>
                <br />
                <Divider />
                <br />
                <li style={{display:"flex",flexDirection:"row",gap:4,alignItems:"center"}}>
                    <Typography variant="h4">Country Name English:</Typography>
                    <Typography variant="h5" color={`secondary`}>{competition.MainInfo.Country.En}</Typography>
                </li>
                <li style={{display:"flex",flexDirection:"row",gap:4,alignItems:"center"}}>
                    <Typography variant="h4">Country Name Arabic:</Typography>
                    <Typography variant="h5" color={`secondary`}>{competition.MainInfo.Country.Ar}</Typography>
                </li>
                <br />
                <Divider />
                <br />
                <li style={{display:"flex",flexDirection:"row",gap:4,alignItems:"center"}}>
                    <Typography variant="h4">Gender:</Typography>
                    <Typography variant="h5" color={`secondary`}>{competition.MainInfo.Gender}</Typography>
                </li>
             </ul>
        </Box>
    </Box>
       
  </Box>
  )
}

export default View