import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, Switch } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from '@mui/icons-material/Close';
import Tags from "@yaireo/tagify/dist/react.tagify" 
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/dashapi`

const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`

const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`
const EditScore = ({event,handleSubmit,handleWinner,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [home,setHome] = useState(event.Score.Home)
  const [away,setAway] = useState(event.Score.Away)


  const HandleSubmitBtn = () =>{
    handleSubmit(home,away)
  }
  const setWinner = (winner) =>{
    if(window.confirm("Are your sure ?")){
      handleWinner(winner)
      closingModal()
    }
  }
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "60%",height:"50%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Edit Score</Typography>
    <br/>
    <Divider />
    <br />

    <Box sx={{display:"flex",justifyContent:'space-around'}}>
          <TextField 
                  sx={{
                    mt:3,
                    width:"49%"
                  }}
                  variant="filled"
                  type="number"
                  value={home}
                  onChange={(e)=>setHome(e.target.value)}
                  label={`Home (${event.Compitiors.Home.Name.En}) Socre ...`}
                  color="secondary"
                 
                />
          <TextField 
            sx={{
              mt:3,
              width:"49%"
            }}
            variant="filled"
            type="number"
            value={away}
            onChange={(e)=>setAway(e.target.value)}
            label={`Away (${event.Compitiors.Away.Name.En}) Score ...`}
            color="secondary"
          />
    </Box>
   
       
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Edit Score</Button>  
    <br />
    <br />
    <Divider />
    <br />
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
        <Button sx={{width:"30%"}} onClick={()=>setWinner('Home')} variant="contained" color="secondary">Home ({event.Compitiors.Home.Name.En})</Button>
        <Button sx={{width:"30%"}} onClick={()=>setWinner('Draw')} variant="contained" color="secondary">Draw</Button>
        <Button sx={{width:"30%"}} onClick={()=>setWinner('Away')} variant="contained" color="secondary">Away ({event.Compitiors.Away.Name.En})</Button>
    </Box>
  </Box>
  )
}

export default EditScore