import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, Switch, Autocomplete, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";

const API_URL = `${GLOBAL_API}/dashapi`
const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`
const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`

const CompetitionsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
}
`
const CompetitorsGQLSchema  = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`

const CsvDataGQLSchema = `
Home{
    ${CompetitorsGQLSchema}
}
Away{
    ${CompetitorsGQLSchema}
}
StartTime
Venue
`

const AddNewSportEvent = ({handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [sports,setSports] = useState([])
  const [compettitons,setCompetitions] = useState([])
  const [seasons,setSeasons] = useState([])  

  const [csvText,setCsvText] = useState("")

  const [selectedSport,setSelectedSport] = useState(null)
  const [selectedCompetition,setSelectedCompetition] = useState(null)
  const [selectedSeason,setSelectedSeason] = useState(null)

  const [matchestoAdd,setMatchesToAdd] = useState([])

  const HandleSubmitBtn =() =>{ 
    handleSubmit(matchestoAdd)  
    closingModal()
  }

  const fetchSeasons = async() =>{
    const res = await axios.post(API_URL,{
        query:`
            query{
                GetSportSeason(id:"${selectedCompetition}"){
                    ${SportSeasonGQLSchema}
                }
            }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetSportSeason){
        setSeasons(res.data.data.GetSportSeason)
    }
  }

  const fetchCompetitions = async() =>{
   
    const res = await axios.post(API_URL,{
        query:`
        query{
            GetAllCompetitions(sport:"${selectedSport}"){
                ${CompetitionsGQLSchema}
            }
        }
        `
    },{
        headers:{
            token:`Bearer ${getAuthentication()}`
        }
    })
    if(res.data.data.GetAllCompetitions){
        setCompetitions(res.data.data.GetAllCompetitions)
    }
  }
 




  const fetchSports = async() =>{
    const res = await axios.post(API_URL,{
      query:`
        query{
          GetSports{
              ${SportGQLSCHEMA}
          }
        }
      `
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.data.data.GetSports){
      setSports(res.data.data.GetSports)
    }
  }

  const AnalyzeCsvText = async() =>{
    const res = await axios.post(API_URL,{
      query:`
        mutation{
          AnalyzeCsvText(csvtext:${JSON.stringify(csvText)}){
            ${CsvDataGQLSchema}
          }
        }
      `
    },{
      headers:{
        token:`Bearer ${getAuthentication()}`
      }
    })
    if(res.data.data.AnalyzeCsvText){
        const matches = res.data.data.AnalyzeCsvText
        let matchesToAdd = []
        for(let i = 0;i<matches.length;i++){
          if(matches[i].StartTime !== null){
            matchesToAdd.push({
              sport:selectedSport,
              home:matches[i].Home,
              away:matches[i].Away,
              starttime:matches[i].StartTime,
              venue:matches[i].Venue,
              competition:selectedCompetition,
              year:selectedSeason
            })
          }
        }
        setMatchesToAdd(matchesToAdd)
    }
  }



  useEffect(()=>{
    if(csvText !== ""){
      AnalyzeCsvText()
    }
    else{
      setMatchesToAdd([])
    }
  },[csvText])

  useEffect(()=>{
    if(selectedCompetition !== null){
        fetchSeasons()
        setSelectedSeason(null)
    }
  },[selectedCompetition])

  useEffect(()=>{
    if(selectedSport !== null){
        fetchCompetitions()
        setSelectedCompetition(null)
    }
  },[selectedSport])

  useEffect(()=>{
    fetchSports()
  },[])

  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "90%",height:"90%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Add New Sport Event</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">You Can't Delete Sport Event ... You Can Just Edit The Score</Typography>
    <br/>
    <Divider />
    <br />

    <Box sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",
        gap:1
    }}>
    <FormControl sx={{mt:3,width:"33%"}}>
    <InputLabel id="demo-simple-select-label" color="secondary">Select A Sport</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedSport}
        label="Competitions"
        onChange={(e)=>setSelectedSport(e.target.value)}
        color="secondary"
      >
        {
          sports.map((lC)=>(
            <MenuItem value={lC.id}>{lC.Name.En} - {lC.Name.Ar}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
       
    {
        compettitons.length > 0 &&   <FormControl sx={{mt:3,width:"33%"}}>
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Competiton</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCompetition}
          label="Competitions"
          onChange={(e)=>setSelectedCompetition(e.target.value)}
          color="secondary"
        >
          {
            compettitons.map((lC)=>(
              <MenuItem value={lC.id}>{lC.MainInfo.Name.En} - {lC.MainInfo.Name.Ar}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    }

{
        seasons.length > 0 &&   <FormControl sx={{mt:3,width:"33%"}}>
        <InputLabel id="demo-simple-select-label" color="secondary">Select A Season</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedSeason}
          label="Competitions"
          onChange={(e)=>setSelectedSeason(e.target.value)}
          color="secondary"
        >
          {
            seasons.map((lC)=>(
              <MenuItem value={lC.id}>{lC.Year}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    }

    </Box>
    {
      selectedSport && selectedCompetition && selectedSeason && <Box sx={{mt:2}}>
      <TextField label="CSV Text ..." color="secondary" multiline rows={8} fullWidth value={csvText} onChange={(e)=>setCsvText(e.target.value)} />
  </Box>
    }
    
    <Box sx={{mt:2}}>
        {
            matchestoAdd.length > 0 && <>
            <Typography variant="h6" color="secondary">Matches To Add: {matchestoAdd.length}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Home</TableCell>
                        <TableCell>Away</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>Start Time (Local Time)</TableCell>
                        <TableCell>Venue</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        matchestoAdd.map((match)=>(
                            <TableRow>
                                <TableCell >
                                    <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                        {
                                            match.home.MainImage?.FilePath && <img width={30} style={{borderRadius:"50%"}} src={`https://cloud.time4.games/${match.home.MainImage.FilePath}`} alt="Home (No Image Yet)" />
                                        }
                                        
                                        <Typography>{match.home.Name.En} - {match.home.Name.Ar}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                    {
                                        match.away.MainImage?.FilePath && <img width={30} style={{borderRadius:"50%"}} src={`https://cloud.time4.games/${match.away.MainImage.FilePath}`} alt="Away (No Image Yet)" />
                                    }
                                    
                                        <Typography>{match.away.Name.En} - {match.away.Name.Ar}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>{match.starttime}</TableCell>
                                <TableCell>{new Date(match.starttime).toLocaleString()}</TableCell>
                                <TableCell>{match.venue}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            </>
        }
    </Box>
   
  
  

    
  

     
   
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Add New Sport Events</Button>  
  </Box>
  )
}

export default AddNewSportEvent