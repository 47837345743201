import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GLOBAL_API } from "../../../data/Links";
import { getAuthentication } from '../../../auth';
import { Box, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import CloseIcon from '@mui/icons-material/Close';

const API_URL = `${GLOBAL_API}/dashapi`;


const PlayedGameGQLSchema = `
id
AppUser{
    MainInfo{
        FirstName
        LastName
        BirthDate
        PhoneNumber{
            CountryCode
            Number
        }
        Country{
            id
            Name{
                En
                Ar
            }
        }
    }
    Credentials{
        Email
    }
}
Status
Predictions
JoinDate
Result
Award
`
const PlayedGames = ({title,game,closingModal}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [users,setUsers] = useState([])

    const fetchPlayedGames = async () =>{
        const res = await axios.post(API_URL,{
            query:`
                query{
                    GetPlayedGamesForScorePrediction(id:"${game.id}"){
                        ${PlayedGameGQLSchema}
                    }
                }
            `
        },{
            headers:{
                token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.GetPlayedGamesForScorePrediction){
            setUsers(res.data.data.GetPlayedGamesForScorePrediction)
        }
    }

    useEffect(()=>{
        if(game){
            fetchPlayedGames()
        }
    },[game])
    return (
        <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "70%",height:"80%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>{title}</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">{game.MainInfo.Name.En} - {game.MainInfo.Name.Ar}</Typography>
    <br />
    <Divider />
    <br />
    <Box>
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Typography color="secondary" variant="h4">Players</Typography>
            <Typography  variant="h6">Total Players: {users.length}</Typography>

        </Box>
       <Box>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Birth Date</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Join Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Award</TableCell>
                </TableRow>

            </TableHead>
            <TableBody>
                {
                    users.map((user)=>(
                        <TableRow>
                            <TableCell>{user.AppUser.MainInfo.FirstName} {user.AppUser.MainInfo.LastName}</TableCell>
                            <TableCell>{user.AppUser.MainInfo.Country.Name.En} - {user.AppUser.MainInfo.Country.Name.Ar}</TableCell>
                            <TableCell>{new Date(user.AppUser.MainInfo.BirthDate).toLocaleDateString()}</TableCell>
                            <TableCell><a href={`tel:${user.AppUser.MainInfo.PhoneNumber.CountryCode}${user.AppUser.MainInfo.PhoneNumber.Number}`}>{user.AppUser.MainInfo.PhoneNumber.CountryCode} {user.AppUser.MainInfo.PhoneNumber.Number}</a></TableCell>
                            <TableCell><a href={`mailto:${user.AppUser.Credentials.Email}`}>{user.AppUser.Credentials.Email}</a></TableCell>
                            <TableCell>{user.JoinDate && new Date(user.JoinDate).toLocaleDateString()}</TableCell>
                            <TableCell>{user.Status}</TableCell>
                            <TableCell>{user.Result}</TableCell>
                            <TableCell>{user.Award}</TableCell>
                        </TableRow> 
                    ))
                }
            </TableBody>
        </Table>
       </Box>
    </Box>

    </Box>

    )
}

export default PlayedGames