import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/dashapi`

const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`

const Season = ({competition,closingModal,handleSubmit}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [seasons,setSeasons] = useState([])
  const [year,setYear] = useState('')
 


  const fetchApiSeasons = async(id) =>{
      const res = await axios.post(API_URL,{
        query:`
          query{
              GetSportSeason(id:"${id}"){
                ${SportSeasonGQLSchema}
              }
          }
        `
      },{
        headers:{
          token:`Bearer ${getAuthentication()}`
        }
      })
      if(res.data.data.GetSportSeason){
        setSeasons(res.data.data.GetSportSeason)
      }
  }
 

  const HandleSubmitBtn = () => {
    handleSubmit(year)
    closingModal()
  }

  useEffect(()=>{
    fetchApiSeasons(competition.id)
  },[competition])
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "40%",height:"70%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Competition Season</Typography>
    <Typography sx={{textAlign:"center"}} variant="h6">{competition.MainInfo.Name.En} - {competition.MainInfo.Name.Ar}</Typography>
    <br/>
    <Divider />
    <br />
   
    <Box sx={{display:'flex',flexDirection:"row",gap:2,alignItems:"center",alignContent:"center"}}>
        <TextField sx={{width:"50%"}} color="secondary" placeholder="Year..." value={year} onChange={(e)=>setYear(e.target.value)} />
        <Button onClick={HandleSubmitBtn} variant="contained" color="secondary">Add New Season</Button>  
        </Box>
    
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Year</TableCell>
               
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    seasons.map((lS)=>(
                        <TableRow key={lS.id}>
                            <TableCell>{lS.Year}</TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    </TableContainer>
       
  </Box>
  )
}

export default Season