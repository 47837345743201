import { Box, Button, Divider, TextField, useTheme, Typography, IconButton, TextareaAutosize, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, ButtonGroup, useMediaQuery, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const Awards = ({ competition,submitHandler, closingModal }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [loadedSeasons, setLoadedSeasons] = useState([])
  const [showSpinner, setShowSpinner] = useState(true)

  const [awards, setAwards] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  const chnageIsWin = (index) => {
    setAwards(prevAwards => {
      const newAwards = [...prevAwards];
      if (!newAwards[index].isWin) {
        for (let i = index; i < newAwards.length; i++) {
          newAwards[i].isWin = true;
        }
      } else {
        for (let i = 0; i <= index; i++) {
          newAwards[i].isWin = false;
          newAwards[i].Award = "";
           
        }
      }
      return newAwards;
    });
  }

  const HandleSubmitBtn = () =>{
    submitHandler(JSON.stringify(awards))
    closingModal()
  }
  const setupAwards = () => {
    let data = []
    for (let i = 0; i <= competition.SportEvents.length; i++) {
      data.push({
        Index: i,
        isWin: true,
        Award: ""
      })
    }
    setAwards(data)
  }

  useEffect(() => {
    setTotalCount(competition.SportEvents.length)
    if (competition.Awards.length > 0) {
      setAwards(competition.Awards)
    }
    else {
      setupAwards()
    }
  }, [competition])

  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "60%", height: "80%", bgcolor: colors.primary[400], overflowY: 'scroll', boxShadow: 24, p: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={closingModal} color="error"><CloseIcon /></IconButton>
      </Box>

      <Typography variant="h3" color="secondary" sx={{ textAlign: "center" }}>Score Prediction Awards</Typography>
      <Typography sx={{ textAlign: "center" }} variant="h6">{competition.MainInfo.Name.En} - {competition.MainInfo.Name.Ar}</Typography>
      <br />
      <Typography color={`error`} sx={{ textAlign: "center" }}>Use this dashboard to set awards based on match outcomes. Checked switches indicate winning scores, while unchecked switches indicate losing scores. Adjust switches to manage awards for each score.</Typography>

      {
        awards.map((aw, index) => (
          <Box key={index} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", mt: 2 }}>
            <Typography variant="h3" color={`secondary`}>{aw.Index} / {totalCount}</Typography>
            <Switch disabled={competition.Infos.Locked} onChange={() => chnageIsWin(aw.Index)} checked={aw.isWin} color="secondary" />
            <TextField disabled={(competition.Infos.Locked || !aw.isWin)?true:false} color="secondary" sx={{ width: '30%' }} placeholder={`Award if achieving ${aw.Index + 1} out of ${totalCount}`} value={aw.Award} onChange={(e) => {
              const newAwards = [...awards];
              newAwards[aw.Index].Award = e.target.value;
              setAwards(newAwards);
            }} />
          </Box>
        ))
      }

    <Button onClick={HandleSubmitBtn} disabled={competition.Infos.Locked} sx={{mt:4,width:'100%'}} variant="contained" color="secondary">Set Awards</Button>  

    </Box>
  )
}

export default Awards
