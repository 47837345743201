import { Box, Button, Divider, TextField,useTheme,Typography,IconButton,TextareaAutosize,Select, MenuItem,InputLabel,FormControl,FormControlLabel,Checkbox,ButtonGroup, useMediaQuery } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close';

import 'react-image-crop/dist/ReactCrop.css'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const DASH_API = `${GLOBAL_API}/dashapi`
const EXTERNAL_URL = `${GLOBAL_API}/SportRadar/Soccer`

const CompetitionGQLSCHEMA = `
id
CompetitionId
MainInfo{
    Name{
        En
        Ar
    }
}
`
const EditTopCompetitionCount = ({topcompetition,handleSubmit,closingModal}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [count,setCount] = useState(topcompetition.Count)



  const HandleSubmitBtn = () =>{
    handleSubmit(count)
    closingModal()
  }


 
  
  return (
    <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "30%",height:"40%",bgcolor: colors.primary[400],overflowY:'scroll',boxShadow: 24,p: 4}}>
    <Box  sx={{display:"flex",justifyContent:"flex-end"}}><IconButton onClick={()=>closingModal()} color="error"><CloseIcon /></IconButton></Box>
    
    <Typography variant="h3" color="secondary" sx={{
      textAlign:"center"
    }}>Edit Top Competition Index</Typography>
    <br/>
    <Divider />
    <br />
   
    <Box>
    <TextField 
        type="number"
        sx={{
          mt:3,
        }}
        fullWidth
        variant="filled"
        value={count}
        onChange={(e)=>setCount(e.target.value)}
        label="Item Index ..."
        color="secondary"/>
    </Box>
       
    <Button onClick={HandleSubmitBtn} sx={{mt:2}} variant="contained" color="secondary">Edit</Button>  
  </Box>
  )
}

export default EditTopCompetitionCount