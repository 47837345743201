import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react'
import { tokens } from '../../../theme';
import axios from 'axios';
import { getAuthentication } from '../../../auth';
import Header from '../../../components/Header';
import { Box, Button, CircularProgress, IconButton, Modal, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { GLOBAL_API } from '../../../data/Links';
const API_URL = `${GLOBAL_API}/dashapi`;

const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`
const Countries = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isLoaderVisible,setLoaderVisibility] = useState('flex')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)


    const [countries,setCountries] = useState([])


   

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0)
    }

    const toggleAvaibility = async(country) =>{
        const res = await axios.post(API_URL,{
            query:`
                mutation{
                    ChangeCountryAvaibility(id:"${country.id}",enable:${!country.Enable}){
                        ${CountryGQLSchema}
                    }
                }
            `
        }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.ChangeCountryAvaibility.id){
            fetchData()
        }
    }

    
    const fetchData = async() =>{
        setLoaderVisibility('flex')

        const res = await axios.post(API_URL, {
            query: `
              query {
                GetCountryList{
                  ${CountryGQLSchema}
                }
              }
            `
          }, {
            headers: {
              token: `Bearer ${getAuthentication()}`
            }
        })
        if(res.data.data.GetCountryList){
            setCountries(res.data.data.GetCountryList)
            setLoaderVisibility('none')
        }
    }

   

    

    useEffect(()=>{
        fetchData()
    },[])
    return (
        <Box m="20px">
            <Header title={`Countries`} subtitle={`Manage Available Countries`} />
            
            <Box sx={{
                mb:2,
                display:"flex",
                justifyContent:"space-between"
            }}>

                <Box   sx={{
                                display:isLoaderVisible === 'flex' ? 'flex' : 'none',
                                justifyContent:"center",
                                alignContent:"center",
                                alignItems:"center",
                            }}>
                        <CircularProgress color="secondary"  sx={{mx:2}}/>
                        Fetching Data ... Please Wait
                    </Box>
                    <Box   sx={{
                display:isLoaderVisible === 'flex' ? 'none' : 'flex',
                justifyContent:"flex-start",
                alignContent:"flex-start",
                alignItems:"flex-start",
                width: '70%',
            }}>
                       
                    </Box>
            </Box>
            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiTable-root":{
                    borderRadius:"15px"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                 color: colors.greenAccent[300],
                       },
                "& .MuiTableHead-root": {
                 backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                     },
                "& .MuiTableBody-root": {
                  backgroundColor: colors.primary[400],
                },
                 "& .MuiDataGrid-footerContainer": {
                   borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                 "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                 },
                 "& .MuiTableCell-root":{
                    borderBottom:'none',
                    textAlign:"center"
                 },
                 
                 
                "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
                  color:`${colors.grey[100]} !important`
                }
               }}
              >
                <TableContainer  >
  <Table>
    <TableHead>
      <TableRow >
        <TableCell>English</TableCell>
        <TableCell>Arabic</TableCell>
        <TableCell>Flag</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
        {
            countries.slice(page * rowsPerPage, page * rowsPerPage  + rowsPerPage).map((ad)=>(
                <TableRow key={ad.id}>
                    <TableCell>{ad.Name.En}</TableCell>
                    <TableCell>{ad.Name.Ar}</TableCell>
                    <TableCell>
                        <img width={32} src={`https://cloud.time4.games/Flags/${ad.Shortcut}.png`}/>
                    </TableCell>
                    <TableCell>
                        <Switch color='secondary' onChange={()=>toggleAvaibility(ad)} checked={ad.Enable}/>
                    </TableCell>
                </TableRow>
            ))
        }
    </TableBody>
    

   </Table>
    </TableContainer>
    <TablePagination
  rowsPerPageOptions={[1,5,10, 25, 50, 100]}
  component="div"
  count={countries.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>  
              </Box>
        </Box>
    )
}

export default Countries